/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";
@import "css/stack-interface.css";
@import "css/socicon.css";
@import "css/iconsmind.css";
@import "css/theme.css";
@import "css/font-rubiklato.css";

nav.navbar > .navbar-nav > li > .nav-link {
    font-size: 12px;
    line-height: 2.166666666666667em;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .5px;
    font-family: 'Open Sans',Roboto,Helvetica,Sans-Serif;
    cursor: pointer;
}

nav.navbar > .navbar-nav > li > .btn {
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-style: none;
    padding-top: 4px;
    padding-bottom: 4px;
}

.form-check-input {
    margin-top: .5rem;
}